import { mapState, mapActions, mapGetters } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import IrOrderMassReroute from '../IrOrderMassReroute.vue';

import { OrdersApiService } from '../../_services/orders.api.service';

const orderItemHistoryHeaders = Object.freeze([
  { text: 'Status', value: 'state' },
  { text: 'Print Provider', value: 'printProviderId' },
  { text: 'Created Date', value: 'created' },
  { text: 'Description', value: 'description' },
]);

export default {
  name: 'OrderItems',

  props: {
    loadingError: {
      type: Object,
      default: () => ({}),
    },
    isLoading: Boolean,
  },

  components: {
    IrOrderMassReroute,
  },

  data() {
    return {
      isShort: true,
      orderItemHistoryModalDialog: false,
      orderItemHistoryModalTitle: '',
      orderItemHistoryHeaders,
    };
  },

  computed: {
    ...mapState('orders', ['orderItemHistory']),
    ...mapGetters('orders', ['orderItems']),
    ...mapGetters('accounts', ['accountNameById']),

    rows() {
      return this.isShort ? this.orderItems.slice(0, 3) : this.orderItems;
    },

    noContent() {
      return { show: !this.orderItems.length, text: 'No items' };
    },
  },

  methods: {
    ...mapActions('orders', ['getOrderItemHistory']),

    async openOrderItemHistoryModal(id) {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.getOrderItemHistory({ orderId: this.$route.params.id, id });
        if (!this.orderItemHistory.length) {
          this.$VBlackerTheme.notification.warning('No history items');
        } else {
          this.orderItemHistoryModalTitle = `Order item ${id} history`;
          this.orderItemHistoryModalDialog = true;
        }
      } catch (e) {
        this.$VBlackerTheme.notification.error(e);
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },

    cancelOrderItem({ productType, generatedId }) {
      this.$VBlackerTheme.alert.warning({
        text: `Cancel ${productType}?`,
      }, () => this.wrapToLoadingFn({
        req: OrdersApiService.cancelOrderItem.bind({}, {
          orderId: this.$route.params.id,
          generatedId,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.$VBlackerTheme.notification('Successfully canceled');
          this.$emit('refresh');
        },
      }));
    },

    getTrackingNumbersArray(trackingNumberStr) {
      if (!trackingNumberStr) {
        return ['-'];
      }
      return trackingNumberStr.split('|');
    },

    openIrOrderMassRerouteDialog() {
      this.$refs.IrOrderMassReroute.$_openDialog({
        orderId: this.$route.params.id,
        orderItems: this.orderItems,
      });
    },

    wrapToLoadingFn,
  },
};
