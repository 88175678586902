import { mapState, mapActions, mapGetters } from 'vuex';

const msgItemsHeaders = Object.freeze([
  { text: 'Recipient', value: 'recipientId' },
  { text: 'Created Date', value: 'dateCreated' },
  { text: 'Request', value: 'request' },
  { text: 'Response', value: 'response' },
  { text: 'Error', value: 'error' },
]);

export default {
  name: 'OrderMessages',
  props: {
    loadingError: {
      type: Object,
      default: () => ({}),
    },
    isLoading: Boolean,
  },
  data() {
    return {
      isShort: true,
      msgItemsHeaders,
      expandedItems: {},
      showMsgModal: false,
      modalTitle: '',
      msgText: '',
    };
  },
  computed: {
    ...mapGetters('orders', ['orderMessages']),
    ...mapGetters('accounts', ['accountNameById']),
    ...mapState('messages', ['message', 'messageRequest', 'messageResponse']),
    rows() {
      return this.isShort ? this.orderMessages.slice(0, 3) : this.orderMessages;
    },
    noContent() {
      return { show: !this.orderMessages.length, text: 'No messages' };
    },
  },
  methods: {
    ...mapActions('messages', ['getMessage', 'getMessageRequest', 'getMessageResponse']),
    expandItem(id) {
      this.$set(this.expandedItems, id, !this.expandedItems[id]);
    },
    showError(text, id) {
      this.msgText = text;
      this.modalTitle = `Error ${id}`;
      this.showMsgModal = true;
    },
    async showMessage(id) {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.getMessage(id);
        if (this.message) {
          this.msgText = this.message;
          this.modalTitle = `Message ${id}`;
          this.showMsgModal = true;
        } else {
          this.$VBlackerTheme.notification.warning('Empty data');
        }
      } catch (e) {
        this.$VBlackerTheme.notification.error(e);
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
    async showMessageRequest(id) {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.getMessageRequest(id);
        if (this.messageRequest) {
          this.msgText = this.messageRequest;
          this.modalTitle = `Message request ${id}`;
          this.showMsgModal = true;
        } else {
          this.$VBlackerTheme.notification.warning('Empty data');
        }
      } catch (e) {
        this.$VBlackerTheme.notification.error(e);
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
    async showMessageResponse(id) {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.getMessageResponse(id);
        if (this.messageResponse) {
          this.msgText = this.messageResponse;
          this.modalTitle = `Message response ${id}`;
          this.showMsgModal = true;
        } else {
          this.$VBlackerTheme.notification.warning('Empty data');
        }
      } catch (e) {
        this.$VBlackerTheme.notification.error(e);
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
  },
};
